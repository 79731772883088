@import '../../style/colors';
@import '../../style/mixin';


.header {
  padding: 40px 0 36px 0;
  background-color: $bgc;
  &_dark {
    background-color: $dark;
    & .header-button {
      border: 1px solid rgba($grey, .2); } }
  &-wrapper {
    display: flex; }
  &-title {
    padding-left: 40px;
    & h1 {
      @include light;
      font-size: 70px;
      line-height: 82px;
      letter-spacing: -2.1px;
      margin: 0;
      margin-bottom: 5px;
      & > span {
        @include light;
        color: $red; } }
    & > span {
      font-size: 20px; } }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey;
    border-radius: 16px;
    height: 69px;
    width: 69px;
    cursor: pointer;
    &__logo {
      background-color: #fff;
      flex-shrink: 0;
      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 4px; }
      & img {
        width: 80%; } }
    &__menu {
      display: block;
      position: relative;
      flex-shrink: 0;
      & span, & span::before, & span::after {
        transition: all .2s;
        position: absolute;
        width: 28px;
        height: 2px;
        background-color: $red;
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -14px; }
      & span {
        &::before, &::after {
          content: ''; }
        &::before {
          transform: translateY(-10px); }
        &::after {
          transform: translateY(10px); } }
      &.active {
        & span {
          background-color: transparent;
          &::before {
            transform: rotate(225deg); }
          &::after {
            transform: rotate(-225deg); } } } }
    &s {
      display: flex;
      align-items: flex-start;
      justify-content: space-between; } } }

@media (max-width: 1199.98px) {
  .header-title {
    & h1 {
      @include adaptive-font(70, 50); }
    & h1.header-title__event {
      @include adaptive-font(70, 40); }
    & > span {
      @include adaptive-font(20, 16); } } }


@media (min-width: 768px) and (max-width: 991.98px) {
  .header {
    padding-top: 30px;
    padding-bottom: 30px; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .header {
    padding-top: 25px;
    padding-bottom: 25px; } }

@media (max-width: 767.98px) {
  .header {
    &-buttons {
      position: relative; }
    &-button__menu {
      position: absolute;
      right: 1px;
      top: 1px; }
    &-wrapper {
      flex-direction: column;
      width: 100%; }
    &-title {
      padding-top: 20px;
      padding-left: 0; } } }

@media (max-width: 575.98px) {
  .header {
    padding-top: 20px;
    padding-bottom: 20px; } }

@media (max-width: 325.98px) {
  .header-title h1 {
    font-size: 46px;
    line-height: 50px; } }

@media (max-width: 320.98px) {
  .header {
    padding-bottom: 10px; } }

@media (max-width: 319.98px) {
  .header-title h1 {
    font-size: 44px; } }

@media (max-width: 289.98px) {
  .header-title h1 {
    font-size: 42px; } }
